@import './variables.scss';
@import './mixins.scss';

/** 
* General
*/
body {
	font-family: 'Poppins', sans-serif;
	background-color: #f9f9fd;
}

img {
	max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Poppins', sans-serif;
}

.logo {
	width: 200px;
}

.logo-collapsed {
	width: 36px;
}

.app-circle-icon {
	cursor: pointer;
	width: 48px;
	height: 48px;
	background: $white-color;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
	-webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
	// -webkit-box-shadow: 1px 1px 2px 0px rgb(0 0 0 / 40%);
	// box-shadow: 1px 1px 2px 0px rgb(0 0 0 / 40%);
}

.app-circle-icon--active {
	background-color: $primary-color;
	color: $white-color;
}

// display
.d-none {
	display: none !important;
}

// dynamically generated helpers
$sizes: 4, 8, 12, 16, 20, 24, 36, 48, 60, 80;
@each $size in $sizes {
    .m-#{$size} {
        margin: $size * 1px;
    }
    .mt-#{$size} {
        margin-top: $size * 1px;
    }
    .mb-#{$size} {
        margin-bottom: $size * 1px;
    }
    .ml-#{$size} {
        margin-left: $size * 1px;
    }
    .mr-#{$size} {
        margin-right: $size * 1px;
    }

    .p-#{$size} {
        padding: $size * 1px;
    }
    .pt-#{$size} {
        padding-top: $size * 1px;
    }
    .pb-#{$size} {
        padding-bottom: $size * 1px;
    }
    .pl-#{$size} {
        padding-left: $size * 1px;
    }
    .pr-#{$size} {
        padding-right: $size * 1px;
    }
}

// margin
.mb-0 {
	margin-bottom: 0px !important;
}

.mb-8 {
	margin-bottom: 8px;
}

.mb-16 {
	margin-bottom: 16px;
}

.mb-24 {
	margin-bottom: 24px;
}

.mb-32 {
	margin-bottom: 32px;
}

.mb-36 {
	margin-bottom: 36px;
}

.mt-0 {
	margin-top: 0px !important;
}

.mt-8 {
	margin-top: 8px;
}

.mt-16 {
	margin-top: 16px;
}

.mt-24 {
	margin-top: 24px;
}

.mt-32 {
	margin-top: 32px;
}

.mt-36 {
	margin-top: 36px;
}

.mr-0 {
	margin-right: 0px !important;
}

.mr-8 {
	margin-right: 8px;
}

.mr-16 {
	margin-right: 16px;
}

.mr-24 {
	margin-right: 24px;
}

.mr-32 {
	margin-right: 32px;
}

.mr-36 {
	margin-right: 36px;
}

.ml-0 {
	margin-left: 0px !important;
}

.ml-8 {
	margin-left: 8px;
}

.ml-16 {
	margin-left: 16px;
}

.ml-24 {
	margin-left: 24px;
}

.ml-32 {
	margin-left: 32px;
}

.ml-36 {
	margin-left: 36px;
}

// padding
.p-0 {
	padding: 0 !important;
}

.p-12 {
    padding: 12px !important;
}

// width
.w-100 {
	width: 100% !important;
}

// height
.h-100 {
	height: 100%;
}

// modal
.ant-modal-header {
	border-top-left-radius: 16px !important;
	border-top-right-radius: 16px !important;
}

.ant-modal-content {
	border-radius: 16px;
}

// form
.ant-input,
.ant-select > .ant-select-selector {
	border-radius: 8px !important;
}

.ant-input-group-wrapper {
	:first-child {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
		border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

	:last-child {
		border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
		border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
	}
}

// toast
.toastContainer {
	z-index: 2000 !important;
}

// title
.page-title {
	.active,
	.inactive:hover {
		color: #11142d !important;
	}

	.inactive {
		color: #6d6d6d !important;
	}
}

/**
* Error pages
*/
.error-container {
	min-height: 100vh;
	flex-direction: column;

	.error-image {
		width: 100%;
		max-width: 500px !important;
	}
}

/** 
* Layout
*/

.container {
	padding: 60px 24px;
}

// .ant-layout.ant-layout-has-sider {
// 	overflow: auto;
// }

// Sidebar
.app-sidebar {
	position: fixed;
	width: 250px !important;
	max-width: 250px !important;
	min-width: 250px !important;
	min-height: 100vh;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 40px 0px !important;
	border-right: solid 2px #e4e4e4;
	transition: all 0.3s;
	z-index: 10;
	top: 0;
	left: 0;

	@include mobile {
		margin-left: -250px;
		max-height: 100vh;
	}

	.sidebar-menu {
		margin-top: 12px;
		border-right: none !important;

		.ant-menu-item:not(.ant-menu-item-only-child),
		.ant-menu-submenu-title {
			height: 56px !important;
			line-height: 56px !important;
			padding: 18px 24px !important;
			// border-radius: 12px;
		}

		.ant-menu-item:not(.ant-menu-item-only-child):hover {
			color: $secondary-color !important;

			a {
				color: $secondary-color !important;
			}
		}

		.ant-menu-item:active {
			background-color: $white-color !important;
			color: $secondary-color !important;
		}

		.ant-menu-item.ant-menu-item-selected:hover {
			color: $white-color !important;
		}

		.ant-menu-item.ant-menu-item-selected::after {
			display: none !important;
		}

		// submenu
		.ant-menu-submenu-title:hover,
		.ant-menu-item-only-child:hover {
			color: $secondary-color !important;

			.ant-menu-submenu-arrow {
				color: $secondary-color !important;
			}

			a {
				color: $secondary-color !important;
			}
		}

		.ant-menu-sub {
			background-color: $white-color !important;
		}

		.ant-menu-submenu-selected {
			.ant-menu-submenu-title {
				background-color: $secondary-color !important;
				color: $white-color !important;
			}

			.ant-menu-submenu-arrow {
				color: $white-color !important;
			}

			.ant-menu-item-only-child.ant-menu-item-selected {
				background-color: $white-color !important;
				a {
					color: $secondary-color !important;
				}
			}
		}
	}

	&:target {
		margin-left: 0;

		+ .mobile-sidebar-bg {
			@include mobile {
				display: block;
				z-index: 9;
			}
		}
	}
}

.mobile-sidebar-bg {
	background-color: #11142d;
	opacity: 0.2;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: none;
}

.app-sidebar.ant-layout-sider-collapsed {
	width: 80px !important;
	max-width: 80px !important;
	min-width: 80px !important;

	.ant-menu-item:not(.ant-menu-item-only-child), .ant-menu-submenu-title {
		padding: 8px 24px !important;
	}
}

.menu-item--collapse.ant-menu-item-active {
	color: unset !important;
}

.app-sidebar::-webkit-scrollbar {
	display: none;
}

.main {
	margin-left: 250px;
	min-height: 100vh;
	transition: all .1s ease-out;

	@include mobile {
		margin-left: 0;
	}
}

.main.main-expanded {
	margin-left: 80px !important;
}

// header
.app-header {
	background: #f9fafc !important;
	padding: 12px 48px 0px;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	.app-btn-round {
		.ant-btn-round.ant-btn-lg {
			font-size: 16px;
			height: 48px;
			background-color: $white-color;
			box-shadow: 0px 4px 4px rgb(0 0 0 / 6%);
			border-radius: 24px;
			font-weight: 600;
		}
	}

	.menu-icon {
		display: none;

		@include mobile {
			display: block;
			color: $primary-color;
			cursor: pointer;
		}
	}

	@include mobile {
		justify-content: space-between;
		padding: 0;
		padding-left: 24px;
		padding-right: 24px;
		box-shadow: 0px 0.12em 1em rgba(0, 0, 0, 0.12);
		position: relative;

		a[href="#app-sidebar"] {
			display: flex;
			align-items: center;
		}

		a[href="#app-sidebar"]::after {
			content: "MENU";
			font-weight: bold;
			margin-left: 8px;
		}
	}
}

.checkout-header {
	background: #f9fafc !important;
	padding: 12px 48px 0px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

// content
.ant-layout-content {
	background: #f9fafc;
	padding: 12px 24px !important;

	@include mobile {
		padding: 12px !important;
	}
}

/**
* Table
*/
.ant-table-content {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
	border-radius: 20px;
	background: $white-color;
}

.ant-table-thead > tr > th {
	background: $primary-color-1;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 600;
}

.ant-table-thead > tr > th::before {
	display: none !important;
}

/**
* Loading
*/
.loader {
	margin-top: 48px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.loader-logo {
	overflow: hidden;
	transform: translatey(0px);
	animation: float 3s ease-in-out infinite;

	img {
		width: 64px;
		height: 64px;
		object-fit: contain;
	}
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

// Inputs
.ant-input-password,
.ant-input-affix-wrapper {
	border-radius: 8px !important;
}

// Links
.link {
	color: $primary-color;
	text-decoration: none;
	background-color: transparent;
	outline: none;
	cursor: pointer;
	transition: color 0.3s;
}

// ant tab
.ant-tabs-top > .ant-tabs-nav::before {
	border-bottom: none !important;
	color: $primary-color;
	text-decoration: none;
	background-color: transparent;
	outline: none;
	cursor: pointer;
	transition: color 0.3s;
}

// font-weight
.weight-5 {
	font-weight: 500;
}
.weight-6 {
	font-weight: 600;
}
.weight-7 {
	font-weight: 700;
}
.weight-9 {
	font-weight: 900;
}

// font-size
.size-12 {
	font-size: 12px;
}
.size-14 {
	font-size: 14px;
}
.size-16 {
	font-size: 16px;
}
.size-20 {
	font-size: 20px;
}

//color

.gray-color {
	color: $gray-color-1;
}

.violet-color {
	color: $primary-color-3;
}

.primary-color {
	color: $primary-color;
}

// font
.uppercase {
	text-transform: uppercase;
}

// upload
.ant-upload.ant-upload-drag {
	background-color: $primary-color-1 !important;
	border: 2px dashed $primary-color-2;
	border-radius: 8px;
	height: 80px;
	padding-left: 24px;
	padding-right: 24px;
}

.ant-upload.ant-upload-drag svg {
	margin-right: 16px;
	color: $primary-color-2;
}

.upload-area--success {
	display: flex;
	width: 100%;
	line-height: 44px;
	padding: 16px 24px;
	justify-content: space-between;
	align-items: center;
	background-color: $success-color-1;
	border: 2px dashed $success-color;
	border-radius: 8px;

	svg {
		margin-right: 16px;
		color: $success-color;
	}
}

// form
.ant-form-item-extra {
	font-size: 12px !important;
}

// loading
.loading-icon {
	font-size: 20px;
	color: $primary-color;
}

// text
.text-primary {
    color: $primary-color;
}

.text-success {
	color: $success-color !important;
}

.text-warning {
	color: $warning-color !important;
}

.text-error {
	color: $secondary-color !important;
}

.text-gray {
	color: $gray-color-1 !important;
}

// description
.ant-descriptions-item-label {
	font-weight: 500;
}

// img
.img-contain {
	object-fit: contain;
}

// dropdown
.ant-dropdown-trigger {
	font-weight: 500 !important;
	cursor: pointer;
}

// avatar
.ant-avatar-icon {
	display: flex;
	justify-content: center;
	align-items: center;
}

// nowrap group 
.nowrap {
    white-space: nowrap;
}

// upload button
.upload-preview-img {
	height: 80px;
	width: auto;
}

.checkbox-vertical {
	.ant-checkbox-wrapper {
		display: flex;
	}
}


.react-json-view {
	word-break: break-all;
}